import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import Icon from "../../components/ui/icon";
import Link from "../../components/ui/link";
import Text from "../../components/ui/text";
import { SettingsContext } from "../../context/settings.context";
import * as styles from "./styles.module.scss";

const Footer = () => {
  const { logo, copyright, legalLinks, socialLinks, customFooterColor } = useContext(SettingsContext);

  useEffect(() => {
    if (customFooterColor) {
      document?.documentElement?.style?.setProperty(
        '--footer-color',
        customFooterColor
      );
    }
  }, []);

  return (
    <div className={styles.footer}>
      <Container className={styles.container}>
        {logo && (
          <Link url={"/"} className={styles.logoLink}>
            <img width="150px" src={logo?.file?.url} alt={logo.altText} />
          </Link>
        )}
        <div className={styles.metaInfo}>
          {copyright && (
            <Text as="span" type="copy" className={styles.copyright}>
              {copyright}
            </Text>
          )}
          {legalLinks?.length && (
            <div className={styles.legalLinksContainer}>
              {legalLinks?.map((link) => (
                <Link key={link.text} {...link} className={styles.legalLink}>
                  <Text as="span" type="copy">
                    {link.text}
                  </Text>
                </Link>
              ))}
            </div>
          )}
        </div>
        {socialLinks?.length && (
          <div className={styles.socialLinks}>
            {socialLinks?.map((social) => (
              <Link
                key={social.label}
                url={social.url}
                className={styles.socialLink}
              >
                <Icon type="fab" name={social.text} className={styles.icon} />
              </Link>
            ))}
          </div>
        )}
      </Container>
    </div>
  );
};


export default Footer;
