import React, { useContext, useEffect } from "react";
import { SettingsContext } from "../../context/settings.context";
import DesktopHeader from "./components/desktopHeader";
import MobileHeader from "./components/mobileHeader";
import * as styles from "./styles.module.scss";

const Header = () => {
  const {
    logo,
    navigation,
    navigationCta,
    copyright,
    legalLinks,
    socialLinks,
    customHeaderColor
  } = useContext(SettingsContext);
  useEffect(() => {
    if (customHeaderColor) {
      document?.documentElement?.style?.setProperty(
        '--header-color',
        customHeaderColor
      );
    }
  }, []);

  return (
    <div className={styles.header}>
      <DesktopHeader
        logo={logo}
        navItems={navigation}
        cta={navigationCta}
        className={styles.desktopHeader}
      />
      <MobileHeader
        logo={logo}
        navItems={navigation}
        copyright={copyright}
        legalLinks={legalLinks}
        socialLinks={socialLinks}
        cta={navigationCta}
        className={styles.mobileHeader}
      />
    </div>
  );
};

export default Header;
