import pt from 'prop-types';
import React, { useContext, useEffect } from 'react';
import Hero from '../../components/hero';
import { SettingsContext } from '../../context/settings.context';
import Footer from '../footer';
import Header from '../header';
import SEO from '../seo';
import CookieBanner from '../cookieBanner';
import * as styles from './styles.module.scss';

const Layout = ({ children, title, description, hero }) => {
  const siteSettings = useContext(SettingsContext);
  const { customLinksColor, bodyBackgroundColor } = siteSettings;

  useEffect(() => {
    if (bodyBackgroundColor) {
      document?.documentElement?.style?.setProperty(
        '--body-bg-color',
        siteSettings?.bodyBackgroundColor
      );
    }
  }, []);

  useEffect(() => {
    if (customLinksColor) {
      document?.documentElement?.style?.setProperty(
        '--links-color',
        customLinksColor
      );
    }
  }, []);
  return (
    <div className={styles.outterContainer}>
      <SEO
        title={`${siteSettings.siteTitle} - ${title}`}
        description={description}
        author={siteSettings.siteAuthor}
      />
      <Header />
      {hero && <Hero {...hero} />}
      <main>{children}</main>
      <Footer siteSettings={siteSettings} />
      <CookieBanner siteSettings={siteSettings} />
    </div>
  );
};

Layout.propTypes = {
  hero: pt.object,
  title: pt.string.isRequired,
  description: pt.string.isRequired,
};

Layout.defaultProps = {
  hero: null,
};

export default Layout;
