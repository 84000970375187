import { Turn as Hamburger } from "hamburger-react";
import pt from "prop-types";
import React, { useEffect, useState } from "react";
import Icon from "../../../../components/ui/icon";
import Link from "../../../../components/ui/link";
import Text from "../../../../components/ui/text";
import { combine } from "../../../../helpers/styles";
import * as styles from "./styles.module.scss";

const MobileHeader = ({
  logo,
  navItems,
  copyright,
  legalLinks,
  socialLinks,
  cta,
  className,
}) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      return (document.body.style.position = "fixed");
    }
    return (document.body.style.position = "static");
  }, [isOpen]);

  return (
    <div className={className && className}>
      <Link url={"/"} className={styles.logoLink}>
        <img src={logo?.file?.url} alt={logo?.description} />
      </Link>
      <Hamburger onToggle={setOpen} color="#FFFFFF" />
      <div className={combine(styles.menu, isOpen && styles.showing)}>
        <div className={styles.navigation}>
          {navItems?.map((navItem, idx) => (
            <Link key={idx} url={navItem?.url} className={styles.link}>
              <Text as="h4" type="h1" variant="bold">
                {navItem?.text}
              </Text>
            </Link>
          ))}
          {cta && 
            <Link url={cta.url} className={styles.link}>
              <Text as="h4" type="h1" variant="bold">
                {cta.text}
              </Text>
            </Link>
          }
        </div>
        <div className={styles.socials}>
          {socialLinks?.map((social, idx) => (
            <Link
              key={`socialLink-${idx}`}
              {...social}
              className={styles.socialLink}
            >
              <Icon
                type="fab"
                name={social?.text}
                className={styles.icon}
                size="lg"
              />
            </Link>
          ))}
        </div>
        <div className={styles.copyAndLegal}>
          {copyright && (
            <Text type="h4" className={styles.copyright}>
              {copyright}
            </Text>
          )}
          <div className={styles.legalLinks}>
            {legalLinks?.map((legalLink, idx) => (
              <Link key={`legalLink-${idx}`} {...legalLink}>
                <Text type="h4">{legalLink?.text}</Text>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
MobileHeader.propTypes = {
  navItems: pt.array,
  siteSettings: pt.object,
  className: pt.string,
};
MobileHeader.defaultProps = {
  navItems: [],
  siteSettings: {},
  className: "",
};

export default MobileHeader;
