import pt from "prop-types";
import React from "react";
import Link, { linkProptypes } from "../../../../components/ui/link";
import Text from "../../../../components/ui/text";
import * as styles from "./styles.module.scss";

const DesktopHeader = ({ logo, navItems, cta, className }) => {
  return (
    <div className={className && className}>
      <div className={styles.navigationContainer}>
        <Link url={"/"} className={styles.logoLink}>
          <img src={logo?.file?.url} alt={logo?.description} />
        </Link>
        {navItems?.length > 0 && (
          <ul className={styles.navListContainer}>
            {navItems?.map((item, idx) => (
              <li key={`navItem-${idx}`} className={styles.navItem}>
                <Link url={item?.url} className={styles?.link}>
                  <Text as="span" type="h5" className={styles.linkText}>
                    {item?.text}
                  </Text>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      {cta && (
        <>
          <Link {...cta}>
            <Text as="span" type="copy">
              {cta.text}
            </Text>
          </Link>
        </>
      )}
    </div>
  );
};
DesktopHeader.propTypes = {
  logo: pt.object,
  navItems: pt.array,
  cta: pt.shape(linkProptypes),
  className: pt.string,
};
DesktopHeader.defaultProps = {
  logo: {},
  navItems: [],
  cta: null,
  className: "",
};

export default DesktopHeader;
